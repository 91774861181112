import { render, staticRenderFns } from "./FinalG07.vue?vue&type=template&id=58e58391&scoped=true&"
import script from "./FinalG07.vue?vue&type=script&lang=js&"
export * from "./FinalG07.vue?vue&type=script&lang=js&"
import style0 from "./FinalG07.vue?vue&type=style&index=0&id=58e58391&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58e58391",
  null
  
)

export default component.exports